import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { PageHeader, Services } from "../components/sections"
import {
  HelpItprocurement,
  itprocurement,
  presales,
  pricing,
  rapidservice,
  warehousing,
} from "../images/itprocurement"
import {
  BlackSection,
  DetailSection,
  IntroSection,
  OfferSection,
  Points,
} from "../components/structure"
import { AboutCard } from "../components/elements"

const weOffer = [
  {
    id: 1,
    src: rapidservice,
    title: "Rapid Service",
    content:
      "Our service is quick and reliable. We take care of all parts of the IT procurement process, from consultations and supplying products to setup and configuration. We’ll get your new products up and running as quickly as possible so you can reap all the benefits.",
    order: 0,
  },
  {
    id: 2,
    src: pricing,
    title: "Competitive Pricing",
    content:
      "IT procurement can be costly, but we have the best resources at our disposal to ensure that our service is affordable for businesses. Get in touch with us today to find out more about our competitive pricing structure.",
    order: 1,
  },
  {
    id: 3,
    src: warehousing,
    title: "Warehousing and Inventory",
    content:
      "We always utilise premium warehouse and inventory strategies to ensure that the IT products we procure are of the best quality. Let us set you up with the most effective digital tools and watch your business thrive.",
    order: 0,
  },
  {
    id: 4,
    src: presales,
    title: "Pre-sales Consultation",
    content:
      "Our IT procurement service always begins with a pre-sales consultation. We work closely with you to determine exactly what hardware and software your business needs before providing you with the products that will ultimately cut costs, increase productivity, and drive growth.",
    order: 1,
  },
]

const points = [
  {
    id: 1,
    point: "Cutting costs on overpriced products",
  },
  {
    id: 2,
    point:
      "Gaining software and hardware that will increase efficiency and stimulate growth",
  },
  {
    id: 3,
    point: "Utilising training that will improve productivity",
  },
]
export default function ITProcurement() {
  const data = useStaticQuery(graphql`
    query {
      index: file(relativePath: { eq: "headers/itProcurement.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const index = [
    data.index.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.30),
      rgba(0, 0, 0, 0.30))`,
  ].reverse()
  return (
    <Layout>
      <SEO title="IT Procurement" />
      <PageHeader
        fluid={index}
        title="Stop wasting money on overpriced IT products"
        catchy="IT Procurement"
        subtitle="Call us today for affordable and reliable IT product"
      />
      <IntroSection
        title="IT Procurement"
        content="The right IT resources can be the difference between a company that fails and a company that flourishes. But you don’t have to understand what hardware and software your business needs—we’ll do it for you! We provide the right IT products for your business, install and configure them, and provide training so you and your team can use your new setup to help your business soar."
        src={itprocurement}
      />
      <BlackSection title="Call us today for affordable and reliable IT products" />
      <IntroSection
        title="How we will help you?"
        content="When it comes to IT procurement, we have years of knowledge and premium resources at our fingertips to connect you with hardware and software that’s going to advance your business in the long run. We always recognise what products your business needs to reach its full potential before sourcing and providing them. Sit back and relax while we install and configure software and provide easy-to-follow training so you and your team can use the products to improve your business output and foster development."
        src={HelpItprocurement}
      />
      <OfferSection title="What we offer:">
        {weOffer.map((item, id) => (
          <AboutCard
            key={item.id}
            order={item.order}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </OfferSection>
      <DetailSection paragraph1="Our IT procurement service is fully comprehensive, covering all bases from pre-sales consultation to implementation, installation, configuration, and training. We have the expertise to establish which products suit your business and its needs, while also taking into account your budget. Economical and reliable, our IT procurement solutions will lead to your business:">
        {points.map((item, id) => (
          <Points key={item.id}>{item.point}</Points>
        ))}
      </DetailSection>
      <Services />
      <BlackSection title="Take advantage of our affordable and efficient IT procurement services today!" />
    </Layout>
  )
}
